import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'

import Button from '@atoms/UIButton/Button'
import segmentAnalytics from '@lib/segment/analytics'
import { SOMETHING_WENT_WRONG } from '@utils/defaultMessages'

import { usePublisherContext } from '@concepts/Publisher/store/context'
import { useReCaptchaContext } from '@concepts/ReCaptcha/store/contextV3'
import { RecaptchaAction } from '@concepts/ReCaptcha/types/actions'
import { getCartIdFromCookies } from '@config/cookies'
import { Box, Flex, Input, Text } from '@lib/UIComponents'
import { SuccessMessageComponent } from './SuccessMessageComponent'
import useRestAPI from '@lib/http'

export type EmailRegisterProps = {
  btnText: string
  analyticsName: string
  variant?: 'light' | 'dark'
  inputWidth?: string
  executeOnSubmit?: (error?: string) => void
  source?: string
}

const schema = Yup.object({
  email: Yup.string()
    .email('A valid email is required.')
    .required('Enter your email.')
})

const EmailRegister = ({
  btnText,
  analyticsName,
  variant = 'light',
  inputWidth,
  executeOnSubmit,
  source
}: EmailRegisterProps) => {
  const { currentToken } = useReCaptchaContext()
  const publisher = usePublisherContext()
  const APIClient = useRestAPI()

  const onSubmit = async (
    { email }: FormikValues,
    { resetForm, setFieldError, setStatus }: FormikValues
  ): Promise<void> => {
    try {
      const captchaToken = await currentToken(
        RecaptchaAction.RECAPTCHA_NEWSLETTER_SUBSCRIPTION
      )

      const { data } = await APIClient.post('/shops/email_subscriptions', {
        email,
        source,
        encrypted_cart_id: getCartIdFromCookies(),
        ...captchaToken
      })

      resetForm()
      if (executeOnSubmit) executeOnSubmit()

      setStatus({ success: true, alreadySubscribed: data.already_subscribed })
      segmentAnalytics.completeRegistration(
        analyticsName,
        publisher.databaseId as number
      )
      segmentAnalytics.emailCapture({
        emailSubscriberId: data.subscriber_id,
        publisherId: publisher.databaseId as number,
        userId: data.user_id
      })

      setTimeout(setStatus, 10_000)
    } catch (err: any) {
      const { status, data } = err.response
      if (executeOnSubmit) executeOnSubmit(data)

      if (status < 500 && data.message) {
        setFieldError('email', data.message)
      } else {
        setFieldError('email', SOMETHING_WENT_WRONG)
      }
    }
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, status }): JSX.Element => (
        <Form>
          {status?.success ? (
            <SuccessMessageComponent
              alreadySubscribed={status.alreadySubscribed}
            />
          ) : (
            <>
              <Box
                sx={{
                  flex: 1,
                  mr: 4,
                  width: inputWidth || 'auto',
                  span: {
                    fontSize: 'xs'
                  }
                }}
              >
                <Field
                  id="signup-newsletter"
                  type="email"
                  name="email"
                  placeholder="Email"
                  aria-label="Email address"
                  as={Input}
                  variant="unstyled"
                />
                <ErrorMessage name="email" component={Text} />
              </Box>
              <Flex sx={{ margin: 'auto 0' }}>
                <Button
                  type="submit"
                  isDisabled={isSubmitting}
                  variant={variant}
                >
                  {btnText}
                </Button>
              </Flex>
            </>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default EmailRegister
